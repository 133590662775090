<template>
<section class="mt-20 flex justify-center">
    <div class="2xl:w-1/3 md:w-1/2 w-4/5 leading-loose">
        <h1 id="top">Einwilligungserklärung in die Verarbeitung
            personenbezogener Daten gemäß DSGVO (Datenerhebung)</h1>
        <p>Ich stimme der Verarbeitung der im folgenden aufgeführten
            personenbezogenen Daten zu den aufgefuehrten Zwecken durch die
            Andreae Technologies UG (haftungsbeschr&auml;nkt)
            (im folgenden Poselytics genannt) zu:</p>

        <h2>Art der Daten</h2>
        <p>Folgende personenbezogene Daten dürfen durch Poselytics verarbeitet werden:</p>
        <ol class="list-disc list-inside py-3">
            <li>Ganzkörpervideoaufnahmen</li>
            <li>Gesundheitsdaten als Ergebnis der Auswertung der Ganzkörpervideoaufnahmen</li>
            <li>E-Mail-Adresse</li>
            <li>Koerpermerkmale</li>
        </ol>
        <h2>Zweck der Datenverarbeitung</h2>
        <p>Diese Daten werden nur zu folgenden Zwecken verarbeitet:</p>
        <ol class="list-disc list-inside py-3">
            <li>Trainingsdaten für maschinelles Lernen</li>
            <li>Ergebnismitteilung via E-Mail nach der Datenauswertung durch medizinisch
                geschulte Fachkräfte (optional)</li>
            <li>Benachrichtigungen über den Projektverlauf via E-Mail (optional; “Newsletter”)</li>
        </ol>
        <h2>Datensicherheit und -weitergabe</h2>
        <p>
            Diese Daten werden nur von berechtigten Personen unter Einhaltung einer angemessenen
            Datensicherheit bearbeitet. Eine etwaige Weitergabe der Daten erfolgt ausschließlich
            an nach § 15 AktG verbundene Unternehmen unter Einhaltung aller gesetzlichen
            Vorschriften, und von ausschließlich pseudonymisierten Daten an vertraglich zur
            Verschwiegenheit und Einhaltung der Datensicherheit und des Datenschutzes
            verpflichteten medizinisch geschulten Fachkräften zur Auswertung der Daten. Die
            Datenverarbeitung erfolgt auf europäischen Servern in der Google Cloud.
        </p>
        <h2>Widerrugsrecht</h2>
        <p>
            Der/die Einwilligende hat das Recht, die Einwilligung jederzeit mit Wirkung für die
            Zukunft ohne Angabe von Gründen zu widerrufen. Ab Zugang der Widerrufserklärung
            werden die Daten unverzüglich gelöscht, insofern keine gesetzlichen
            Aufbewahrungsfristen entgegenstehen und der Widerruf wirksam ist. Die Wirksamkeit der
            bis zum Widerruf der Einwilligung getätigten Datenverarbeitung bleibt unberührt.
        </p><br/>
        <p>
            Der Widerruf ist an die folgende E-Mail-Adresse zu richten: hi@poselytics.com
        </p>
    </div>
</section>
</template>

<script>

export default {
  name: 'DataCollection',
  components: {
  },
};
</script>
<style scoped>
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    h1 {
        @apply text-3xl text-primary-dark font-bold py-3;
        }
    h2 {
        @apply text-xl text-primary-dark font-bold py-2;
        }
    /* li {
        @apply pl-6;
    } */
}
</style>
